import React, { useState } from "react"
import background from "@/shared/assets/BG.webp"
import { ReactComponent as GoogleButton } from "@/shared/assets/GoogleButton.svg"
import { ReactComponent as AppStoreButton } from "@/shared/assets/AppStoreButton.svg"
import cls from "./main.module.scss"
import { TextField } from "@/shared/ui/TextField/TextField"
import Modal from "@/shared/ui/Modal/Modal"
import {post} from "axios";

export const Main = () => {
    const [email, setEmail] = useState("")
    const [open, setOpen] = useState(false)

    const sendSupport = () => {
        if (ValidateEmail(email)) {
            fetch("https://hz.axgrid.com:8099/hitandshatter/support",
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json'},
                    body: JSON.stringify( {email: email})
                }).then(r => {
                setOpen(true)
                console.log("response status", r.status)
            })
        }
    }

    function ValidateEmail(_email) {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (_email.match(validRegex)) {
            return true;
        } else {
            alert("Invalid email address!");
            return false;
        }
    }

    return (
        <div
            style={{
                backgroundImage: `url(${background})`,
            }}
            className={cls.wrapper}>
            <div className={cls.policiesDesk}>
                <a href='privacy.html'>Privacy</a>
                <a href='terms.html'>Terms</a>
            </div>

            <div className={cls.contentWrapper}>
                <div className={cls.container}>
                    <a href='https://play.google.com/store/apps/details?id=com.AxGrid.KnifeMegaHit'>
                        <GoogleButton
                            className={cls.GoogleButton}
                        />
                    </a>
                    <a href='https://testflight.apple.com/join/EwbrtphS'>
                        <AppStoreButton
                            className={cls.AppStoreButton}
                        />
                    </a>
                </div>

                <div className={cls.licenseCheckForm}>
                    <div className={cls.policies}>
                        <a href='privacy.html'>Privacy</a>
                        <a href='terms.html'>Terms</a>
                    </div>
                    <div className={cls.headContainer}>
                        <h3>Do you need any support?</h3>
                    </div>
                    <div className={cls.bottomContainer}>
                        <TextField
                            value={email}
                            onChange={(e) =>
                                setEmail(e.target.value)
                            }
                            className={cls.licenseField}
                            withoutWarning
                            simplePlaceholder
                            isValid={true}
                            placeholder={"Enter your email"}
                        />
                        <button
                            onClick={sendSupport} className={cls.checkButton}>
                            <svg
                                className={cls.DiagArrow}
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'>
                                <path
                                    d='M7 17L17 7M17 7H8M17 7V16'
                                    stroke='white'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={open}
                setOpen={setOpen}>
                <div className={cls.confirm}>
                    <svg
                        className={cls.icon}
                        width='48'
                        height='48'
                        viewBox='0 0 48 48'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M44 22.16V24C43.9975 28.3128 42.601 32.5093 40.0187 35.9636C37.4363 39.4179 33.8066 41.9449 29.6707 43.1678C25.5349 44.3906 21.1145 44.2438 17.0689 42.7491C13.0234 41.2545 9.56931 38.4922 7.22192 34.8741C4.87453 31.256 3.75958 26.9761 4.04335 22.6726C4.32712 18.3691 5.99441 14.2726 8.79656 10.9941C11.5987 7.71561 15.3856 5.43074 19.5924 4.48026C23.7992 3.52979 28.2005 3.96465 32.14 5.71997M44 7.99997L24 28.02L18 22.02'
                            stroke='#15CF74'
                            stroke-width='4'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                        />
                    </svg>
                    <p>
                        Your request has been received, and
                        we will contact you within 12 hours!
                    </p>
                </div>
            </Modal>
        </div>
    )
}
