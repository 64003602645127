import React, { useRef, useState } from "react"
import { classNames } from "@/shared/lib/utils/classNames"
import cls from "./text_field.module.scss"
import useClickOutside from "@/shared/lib/hooks/useClickOutside"

export const TextField = ({
    placeholder,
    value,
    onChange,
    isValid,
    type,
    className,
    maxlength,
    simplePlaceholder,
    withoutWarning,
}) => {
    const [isFocus, setFocus] = useState(false)

    const handleFocus = () => {
        setFocus(true)
        document.getElementById(placeholder).focus()
    }
    const handleBlur = () => {
        setFocus(false)
    }

    const textFieldRef = useRef()

    useClickOutside(textFieldRef, () => handleBlur())

    return (
        <div
            ref={textFieldRef}
            onClick={() => handleFocus()}
            className={classNames(
                cls.formControl,
                [className],
                {
                    [cls.focus]: isFocus,
                    [cls.invalid]: !isValid,
                }
            )}>
            {!simplePlaceholder && (
                <label
                    className={classNames(
                        cls.inputLabel,
                        [],
                        {
                            [cls.focus]:
                                isFocus || value !== "",
                        }
                    )}>
                    {placeholder +
                        (isFocus || value !== ""
                            ? ":"
                            : "*")}
                </label>
            )}
            <input
                id={placeholder}
                type={type}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={value}
                maxLength={maxlength ?? 50}
                onChange={onChange}
                placeholder={simplePlaceholder && placeholder}
                className={cls.input}
            />
            {!withoutWarning && (
                <div
                    className={classNames(cls.warning, [], {
                        [cls.hide]: isValid,
                    })}>
                    Fill this field *
                </div>
            )}
        </div>
    )
}
