import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    buttons: {
                        getStarted: "Get Started",
                        tryForFree: "Try for Free",
                        GetInnovation: "Visit our store",
                        login: "Login",
                        register: "Register",
                    },
                    navigation: {
                        home: "Home",
                        services: "Services",
                        products: "Products",
                        about: "About",
                        contacts: "Contacts",
                    },
                    overview: {
                        title: "Protranslator makes it easy to run your business.",
                        description:
                            "Transform the way you manage translations with our intuitive, user-friendly platform. Save time and resources while ensuring high-quality, precise localization for every project.",
                    },
                    features: {
                        title1: "One-click transfers using neural network",
                        description1:
                            "Experience seamless and intelligent localization with our advanced neural network technology, making transfers faster and more accurate than ever before.",
                        title2: "Team Collaboration and Management",
                        description2:
                            "Efficiently manage your team's projects with intuitive tools. Create plans, assign tasks, and set deadlines. Control access with customizable permissions. Monitor progress in real-time to address bottlenecks. Enhance productivity and collaboration with our management solutions.",
                        title3: "Easy and Accessible One-Click Integration",
                        description3:
                            "Integrate effortlessly with our one-click solution. Access our comprehensive Help Center for step-by-step guidance. Benefit from the support of a dedicated personal manager to ensure a smooth integration. Simplify your process and get started quickly with our user-friendly tools and expert assistance.",
                    },
                    advantages: {
                        advantage1:
                            "Seamlessly localize your software, website or app",
                        advantage2:
                            "Easily import and export files in various formats JSON, XML and more",
                        advantage3:
                            "Work together with your team in real-time, with features designed",
                    },
                    cases: {
                        title1: "For Developers",
                        description1:
                            "Streamline the localization process of your software and applications.",
                        title2: "For Project Managers",
                        description2:
                            "Easily manage and track localization projects with advanced tools",
                        title3: "For Localization Teams",
                        description3:
                            "Collaborate effectively with team members and ensure high-quality translations",
                        title4: "For Businesses ",
                        description4:
                            "Enhance your global presence by efficiently localizing your products and services",
                    },
                    pricingTitle: {
                        title: "Choose your plan",
                        description:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut metus nunc, fermentum id ex non",
                        monthly: "Monthly",
                        yearly: "Yearly",
                    },
                    plans: {
                        title1: "BASIC",
                        description1:
                            "Per member, per month",
                        title2: "STANDARD",
                        description2:
                            "Per member, per month",
                        title3: "Pro",
                        description3:
                            "Per member, per month",
                    },
                    prefers: {
                        prefer1: "3 active projects",
                        prefer2: "Up to 10 blocks",
                        prefer3: "Team Management",
                        prefer4: "API Integrations",
                    },
                },
            },
            de: {
                translation: {},
            },
        },
    })

export default i18n
